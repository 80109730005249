<template>
    <!-- 帮助与反馈列表 -->
    <div class="help grey-bg text-select-none">
        <div class="help-con">
            <div class="help-item" v-for="item in helpList.contents" :key="item.id" @click="goHelpShow(item.id)">
                <div class="help-item-con space-between">
                    <div class="help-title ">{{ item.title }}</div>
                    <img class="help-img" v-show="!isShowIcon" src="~assets/images/user/more-icon.png" />
                    <img class="help-img-icon" v-show="isShowIcon" src="~assets/images/user/help-down.png" />
                </div>
                <div class="help-text" v-show="isShowIcon" v-html="helpText.body"></div>
            </div>


<!--            <van-collapse v-model="activeName" accordion>
                <van-collapse-item  v-for="(item,index) in helpList.contents" :key="index" :name="index">
                    <template #title :name="index">
                        <div>{{item.title}}</div>
                    </template>
                    <div class="help-text" v-html="item.body"></div>
                </van-collapse-item>
            </van-collapse>-->



            <BottomTip v-show="helpList.contents>10"></BottomTip>
        </div>
        <div class="contact-wrap safety-height" >
            <div class="contact" @click="show = true">联系客服</div>
        </div>
        <van-overlay :show="show" :lock-scroll="false" >
            <div class="wrapper align-center">
                <div class="help-overlay">
                    <div class="help-title">扫码添加好友回复“{{userInfo.team_name ? userInfo.team_name : '队伍名称'}}”加入社群</div>
                    <div class="help-body" >
                        <ul class="help-wechat">
                            <li class="help-wechat-con" v-for="(item,index) in qrcodeImg" :key="index">
                                <div class="help-wechat-title"> </div>
                                <img class="help-wechat-img" :src="$confStatic.ossCdn + item + '?getTime=' + new Date().getTime()" alt="" @click="clickImg(item,index)">
                            </li>
                        </ul>
                        <div class="help-tip-con vertical-center">
                            <div class="help-tip">
                                长按识别二维码
                            </div>
                            <div class="help-btn" @click="show = false">知道了</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import { ImagePreview } from 'vant';
import BottomTip from "components/BottomTip";
export default {
    name: "address-index",
    data(){
        return{
            helpList: [],
            show: false,
            qrcodeImg:['images/suantan-service.png'],
            helpText: '',
            isShowIcon: false,
            activeName: '1'

        }
    },
    created() {
        this.getHelp()
    },
    mounted() {
      this.$nextTick(()=>{
        this.$util.domMinHeight()
      })
    },
    methods:{
        // 获取帮助反馈
        getHelp(){
            let _this = this
            let url = _this.$api.Contents+`?type=feedbacks`
            _this.$http.get(url,true).then( res => {
                if(res.data.success){
                    _this.helpList = res.data.data
                }
            } )
        },
        goHelpShow(id){
            this.$router.push('/user/help/show/' + id)
            /*let _this = this
            if (_this.isShowIcon){

                _this.isShowIcon = false
            }else {
                let url = _this.$api.Contents + '/' + id
                _this.$http.get(url,true).then( res => {
                    if(res.data.success){
                        _this.helpText = res.data.data
                        _this.isShowIcon = id
                    }
                } )
            }*/
        },
        // 点击图片
        clickImg(item, index){
            let img = this.qrcodeImg.map(item => {
                return this.$confStatic.ossCdn + item
            })
            ImagePreview({
                images: img,
                startPosition: index,
            })

        },
    },
    components:{
        BottomTip
    },
    computed: {
      userInfo() {
        return this.$store.getters.userInfo;
      }
    }
}
</script>

<style lang="scss" scoped>
    @import "~assets/scss/user/help-feedback/index.scss";
    $r: 750/16/1rem;
    :deep(.van-collapse-item__content){
        padding-top: 0;
        //margin-right: 32/$r;
    }
    :deep(.van-cell__title){
        font-size: 28/$r;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #313A43;
        line-height: 36/$r;
    }
    .help-text{
        margin-top: 30/$r;
        font-size: 24/$r;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #313A43;
        line-height: 36/$r;
    }
    :deep(.van-overlay){
        z-index: 100;
    }
    .wrapper {
        height: 100%;
        .help-overlay{
            width: 600/$r;
            background: #FFFFFF;
            border-radius: 20/$r;
            padding-bottom: 42/$r;
            .help-title{
                width: 100%;
                background: #FF9E73;
                border-radius: 20/$r 20/$r 0/$r 0/$r;
                font-size: 30/$r;
                font-family: Source Han Sans CN;
                font-weight: 500;
                color: #FFFFFF;
                line-height: 46/$r;
                text-align: center;
                padding: 42/$r 62/$r;
                box-sizing: border-box;
            }
            .help-body{
                .help-wechat{
                    display: flex;
                    white-space: nowrap;
                    overflow-x: auto;
                    .help-wechat-con{
                        margin-right: 46/$r;
                        .help-wechat-title{

                            font-size: 30/$r;
                            font-family: Source Han Sans CN;
                            font-weight: 500;
                            color: #333333;
                            line-height: 36/$r;
                            text-align: center;
                        }
                        .help-wechat-img{
                            width: 268/$r;
                            height: 268/$r;
                            margin: 38/$r 0 20/$r;
                            background: #E6E6E6;
                        }
                        &:first-child{
                             margin-left: 166/$r;
                        }
                        &:last-child{
                            padding-right: 166/$r;
                        }

                    }
                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
                .help-tip-con{
                    flex-direction: column;
                    .help-tip{
                        margin: 20/$r 0 36/$r;
                        font-size: 24/$r;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #FF6B29;
                        line-height: 36/$r;
                    }
                    .help-btn{
                        width: 248/$r;
                        height: 74/$r;
                        background: #FF6B29;
                        border-radius: 37/$r;
                        font-size: 32/$r;
                        line-height: 74/$r;
                        font-family: Source Han Sans CN;
                        font-weight: 300;
                        color: #FFFFFF;
                        text-align: center;
                    }
                }

            }
        }
    }
</style>